<template>
  <div>
    <div class="filters row" style="margin-top:0">
      <div class="col-2-3">
      </div>
      <div class="col-1-3">
        <div class="pull-right">
          <button
            v-on:click="openCreate()"
            class="button button-success button-block"
          >
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <datatable :columns="table.columns" :data="widgets" class="table">
        <template slot-scope="{ row }">
          <tr>
            <td>{{ row.id }}</td>
            <td>{{ row.titulo }}</td>
            <td>{{ row.descricao }}</td>
            <td>{{ row.tipo }}</td>
            <td class="actions">
              <button
                type="button"
                :disabled="!row.permiteEditar"
                class="button button-primary"
                v-on:click="openEdit(row)"
              >
                <fa-icon icon="pencil-alt" />
              </button>
              &nbsp;
              <button
                :disabled="!row.permiteEditar"
                type="button"
                name="button"
                v-on:click="deleteConfirm(row.id)"
                class="button button-error"
              >
                <fa-icon icon="trash-alt" />
              </button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <ModalWidget
      :widget="editWidget"
      :tiposPerfis="tiposPerfis"
      :save="saveWidget" />
  </div>
</template>

<script>
import ModalWidget from './components/ModalWidget/ModalWidget';
import { del, post, put } from '../../helpers/apiRequest';

const newWidget = {
  titulo: 'Novo Widget',
  descricao: '',
  tipo: 'CONTEUDO',
  sql: '',
  repositorio: '',
  estrutura: '',
  exibeTitulo: 1,
  permiteEditar: 1,
  preview: '',
  perfis: [],
};

export default {
  name: 'WidgetsTab',
  props: ['widgets', 'tiposPerfis', 'reload'],
  components: { ModalWidget },
  data() {
    return {
      editWidget: { ...newWidget },
      table: {
        columns: [
          { label: 'ID', field: 'id', align: 'center', sortable: false },
          { label: 'Titulo', field: 'titulo', sortable: false },
          { label: 'Descrição', field: 'descricao', sortable: false },
          { label: 'Tipo', field: 'tipo', sortable: false },
          { label: '', field: '', sortable: false },
        ],
      },
    };
  },
  methods: {
    openCreate() {
      this.editWidget = { ...newWidget };
      this.$modal.show('modalWidget');
    },
    openEdit(row) {
      this.editWidget = { ...row };
      this.$modal.show('modalWidget');
    },
    saveWidget(widget) {
      const onSuccess = (res) => {
        this.editWidget = { ...newWidget };
        this.$modal.hide('modalWidget');
        this.reload();
      };
      if (widget.id) {
        put(`/admin/widgets/${widget.id}`, widget).then(onSuccess);
      } else {
        post('/admin/widgets', widget).then(onSuccess);
      }
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/widgets/${id}`)
              .then((json) => {
                this.loading = false;
                this.reload();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>
