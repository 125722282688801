<template>
  <div>
    <div class="filters row" style="margin-top:0">
      <div class="col-2-3">
      </div>
      <div class="col-1-3">
        <div class="pull-right">
          <button
            v-on:click="openCreate()"
            class="button button-success button-block"
          >
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <datatable :columns="table.columns" :data="dashboards" class="table">
        <template slot-scope="{ row }">
          <tr>
            <td>{{ row.id }}</td>
            <td>{{ row.titulo }}</td>
            <td>{{ row.descricao }}</td>
            <td class="actions">
              <button
                type="button"
                class="button button-primary"
                v-on:click="openEdit(row)"
              >
                <fa-icon icon="pencil-alt" />
              </button>
              &nbsp;
              <button
                type="button"
                name="button"
                v-on:click="deleteConfirm(row.id)"
                class="button button-error"
              >
                <fa-icon icon="trash-alt" />
              </button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <ModalDashboard
      :dashboard="editDashboard"
      :widgets="widgets"
      :sistemas="sistemas"
      :tiposPerfis="tiposPerfis"
      :save="saveDashboard"
    />
  </div>
</template>

<script>
import { del, post, put } from '../../helpers/apiRequest';
import { map } from 'lodash';
import ModalDashboard from './components/ModalDashboard/ModalDashboard';

const newDashboard = {
  titulo: 'Nova Dashboard',
  descricao: '',
  widgets: [
    { id: 4, titulo: 'Novo Widget2', x: 0, y: 0, w: 2, h: 2, i: '0' },
    { id: 5, titulo: 'Novo Widget 3', x: 2, y: 0, w: 2, h: 4, i: '1' },
    { id: 3, titulo: 'Novo Widget', x: 4, y: 0, w: 2, h: 5, i: '2' },
  ],
  perfis: [],
  sistemas: [],
};

export default {
  name: 'DashboardTab',
  components: { ModalDashboard },
  props: ['dashboards', 'widgets', 'sistemas', 'tiposPerfis', 'reload'],
  data() {
    return {
      editDashboard: { ...newDashboard },
      table: {
        columns: [
          { label: 'ID', field: 'id', align: 'center', sortable: false },
          { label: 'Titulo', field: 'titulo', sortable: false },
          { label: 'Descrição', field: 'descricao', sortable: false },
          { label: '', field: '', sortable: false },
        ],
      },
    };
  },
  methods: {
    openCreate() {
      this.editDashboard = { ...newDashboard };
      this.$modal.show('modalDashboard');
    },
    openEdit(row) {
      this.editDashboard = { ...row };
      this.$modal.show('modalDashboard');
    },
    saveDashboard(dashboard) {
      const onSuccess = (res) => {
        this.editDashboard = { ...newDashboard };
        this.$modal.hide('modalDashboard');
        this.reload();
      };
      if (dashboard.id) {
        put(`/admin/dashboards/${dashboard.id}`, dashboard).then(onSuccess);
      } else {
        post('/admin/dashboards', dashboard).then(onSuccess);
      }
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/dashboards/${id}`)
              .then((json) => {
                this.loading = false;
                this.reload();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>
